<template>
  <div class="main index" v-if="!isLoading">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(processFormRegister)">
        <div>
          <div class="columns is-multiline">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Settings</b></h1>
            </div>
            <div class="column is-10">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Setting</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `category` }">
                      <h3 class="is-size-6">Category</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-6 breadcrumb-item"
                      v-if="$route.name != 'editCategory'"
                    >
                      Create Category
                    </h3>
                    <h3 class="is-size-6 breadcrumb-item" v-else>
                      Edit Category
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button type="is-hcc" native-type="submit" icon-left="save">
                  Save
                </b-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <!-- For Name Field -->
                <div class="columns is-12">
                  <div class="column is-2">
                    <b>Name</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      name="name"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <b-input v-model="category.name"></b-input>
                      </b-field>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      isLoading: true,
      category: {
        name: null,
      },
    }
  },
  created() {
    if (this.$route.name == 'editCategory') {
      this.fetchData()
    } else {
      this.isLoading = false
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getCategory',
        this.$route.params.categoryId
      )
      this.category = response
      this.isLoading = false
    },
    submitCreateCategory() {
      this.$store.dispatch('createCategory', {
        category: this.category,
      })
    },
    processFormRegister() {
      if (this.$route.name != 'editCategory') {
        this.$store
          .dispatch('storeCategory', this.category)
          .then(response => {
            if (response.data.status == 406) {
              this.danger(response.data.message.name[0])
            } else {
              this.success('Successfully create new category')
              this.$router.push({ name: 'category' })
            }
          })
          .catch(() => {
            // this.snackbarWarningFailedRegistration();
          })
      } else {
        this.$store
          .dispatch('updateCategory', this.category)
          .then(response => {
            if (response.data.status == 406) {
              this.danger(response.data.message.name[0])
            } else {
              this.success('Successfully update category')
              this.$router.push({ name: 'category' })
            }
          })
          .catch(() => {
            // this.snackbarWarningFailedRegistration();
          })
      }
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
